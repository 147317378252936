<template>
  <div>
    <loader v-if="loading" />
    <div v-else class="card card-custom">
      <div class="card-body p-0">
        <div>
          <v-tabs v-model="tab" class="wizard-step">
            <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- 1 -->
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <div>
                    <div class="col-12">
                      <div>
                        <h5 class="mb-10 text-dark">Buyurtma malumotlari</h5>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.zayavka_owner"
                                label="Nomi"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.zayavka_status"
                                label="Status"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="
                                  getCurrZayavka.deliver == 'POS'
                                    ? 'Boshqalar'
                                    : getCurrZayavka.deliver
                                "
                                label="Postavshik"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.price"
                                label="Narxi"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.nds_summa"
                                label="NDS summa"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.price_with_nds"
                                label="NDS bilan Narxi"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="start_date"
                                label="Yuk ketish sanasi"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="end_date"
                                label="Yuk yetib borish sanasi"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="delivered_date"
                                label="Yetqazib berilgan sana"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.post_kg"
                                label="Kg"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                outlined
                                disabled
                                dense
                                :value="getCurrZayavka.post_m3"
                                label="M3"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <div>
                          <a
                            @click="getZayava(getCurrZayavka.contract)"
                            class="btn btn-primary"
                          >
                            Shartnoma
                          </a>
                        </div>
                        <div
                          class="
                            separator separator-primary separator-dashed
                            mt-8
                            mb-5
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- 2 -->
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <div>
                    <div class="col-12">
                      <div class="pb-5" data-wizard-type="step-content">
                        <h5 class="mb-10 text-dark">TTN malumotlari</h5>
                        <div class="d-flex">
                          <div class="col-3 pl-0">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getCurrTnnId.client_name"
                              label="Mijoz nomi"
                            >
                            </v-text-field>
                          </div>
                          <div class="col-3">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getCurrTnnId.contract_number"
                              label="Shartnoma raqami"
                            >
                            </v-text-field>
                          </div>

                          <div class="col-3">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getRegNum"
                              label="Reg Raqami"
                            >
                            </v-text-field>
                          </div>

                          <div class="col-3 pr-0">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getNameModel"
                              label="Brand name/model"
                            >
                            </v-text-field>
                          </div>
                        </div>

                        <div class="d-flex align-items-start">
                          <div class="col-3 pl-0">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getCurrTnnId.reg_number"
                              label="TTN reg raqami"
                            >
                            </v-text-field>
                          </div>

                          <div class="col-3">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getCurrTnnId.created_at"
                              label="Kiritilgan"
                            >
                            </v-text-field>
                          </div>
                          <div class="col-3 pr-0">
                            <v-text-field
                              outlined
                              disabled
                              dense
                              :value="getCurrTnnId.updated_at"
                              label="Yangilangan"
                            >
                            </v-text-field>
                          </div>
                        </div>

                        <div
                          class="
                            separator separator-primary separator-dashed
                            mt-8
                            mb-5
                          "
                        ></div>
                        <div
                          class="col-12 px-0"
                          v-if="getCurrZayavka.direction_truck !== undefined"
                        >
                          <h5 class="my-5 text-dark">Yo'nalish</h5>
                          <v-text-field
                            v-for="phone in getCurrZayavka.direction_truck
                              .direction.citydestination"
                            :key="phone.id"
                            outlined
                            disabled
                            dense
                            :value="phone.name"
                            label="Yo'nalish"
                          >
                          </v-text-field>
                        </div>
                        <div>
                          <a
                            @click="getExcel(getCurrTnnId.zayavka)"
                            class="btn btn-primary w-100 navi-link"
                          >
                            <span class="navi-icon">
                              <i class="flaticon2-file-1"></i>
                            </span>
                            <span class="navi-text">Export EXCEL</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  name: 'zayavkadetails',
  data() {
    return {
      loading: true,
      tab: null,
      items: [
        {
          tab: 'Buyurtma malumotlari'
        },
        {
          tab: 'TTN malumotlari'
        }
      ],
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 0,
        masked: false,
        start_date: '',
        delivered_date: '',
        end_date: ''
      }
    }
  },

  created() {
    this.$store.dispatch('getZayavkaDetails', this.$route.params.id)
    this.$store.dispatch('getTnnById', this.$route.params.id)

    // this.$store.dispatch("getTruckListById", this.$route.params.id);
  },

  watch: {
    getCurrZayavka(val) {
      this.start_date = val.start_date.slice(0, 10)
      this.delivered_date = val.delivered_date.slice(0, 10)
      this.end_date = val.end_date.slice(0, 10)
    }
  },
  computed: {
    getCurrZayavka() {
      const data = this.$store.state.requests.zayavkaById
      return data
    },
    getCurrTnnId() {
      return this.$store.state.requests.alltnnid
    },
    getRegNum() {
      if (
        this.getCurrZayavka.truck !== null &&
        this.getCurrZayavka.truck !== undefined
      ) {
        return (
          this.getCurrZayavka.truck.reg_number_region +
          ' ' +
          this.getCurrZayavka.truck.reg_number
        )
      } else return "Malumot yo'q"
    },
    getNameModel() {
      if (
        this.getCurrZayavka.truck !== null &&
        this.getCurrZayavka.truck !== undefined &&
        this.getCurrZayavka.truck_type !== undefined
      ) {
        if (this.getCurrZayavka.truck.truck_type.brand !== undefined) {
          return (
            this.getCurrZayavka.truck.truck_type.brand.name +
            ' ' +
            this.getCurrZayavka.direction_truck.truck_type.model +
            " (yuk ko'tarish: " +
            this.getCurrZayavka.truck.truck_type.capacity_kg +
            ' kg' +
            ' hajmi: ' +
            this.getCurrZayavka.truck.truck_type.capacity_m3 +
            ' m3)'
          )
        } else {
          return "Malumot yo'q"
        }
      } else {
        return "Malumot yo'q"
      }
    }
  },
  methods: {
    getExcel(val) {
      axios
        .get('zayavkas/ttn/reporttoexcel/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'TTN')
          document.body.appendChild(link)
          link.click()
        })
    },
    getZayava(id) {
      this.$router.push({ name: 'contractdetails', params: { id } })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buyurtma malumotlari' }])
    this.loading = this.$store.state.requests.loadingState
  }
}
</script>

<style></style>
